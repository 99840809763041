import React from "react";

// Customizable Area Start
import {
  DialogContent,
  DialogActions,
  TextField,
  Slider,
  Avatar,
  Button,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  Box,
  Typography,
  Checkbox,
  IconButton,
  InputAdornment,
  Dialog, DialogTitle, DialogContentText ,
  Tooltip,
  Chip
} from "@material-ui/core";
import { userClient2, Services, request ,right,tooltipIcon} from "./assets";
import { styled ,createTheme,MuiThemeProvider} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import StarRateIcon from '@material-ui/icons/StarRate';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded'

const SeeAllLawyersCatalogueButton = styled("button")({
  all: "unset",
  display: "flex",
  gap: 8,
  alignItems: "center",
  cursor: "pointer",
  fontFamily: "DM Sans",
  fontWeight: 400,
  fontSize: 12,
  lineHeight: "15.62px",
  color: "#355EE7",
})

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        backgroundColor: "#fff",
        zIndex:999,
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        width:"450px",
        borderRadius:"12px",
        padding:"10px"
      }
    }
  }
});
// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  PrettoSlider = styled(Slider)({
    cursor: "pointer",
    color: '#4872FF',
    height: 6,
    '& .MuiSlider-track': {
      height: "6px",
      borderRadius: "10px"
    },
    '& .MuiSlider-rail': {
      height: "6px",
      borderRadius: "10px"
    },

    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '6px solid  #4872FF',
      bottom: "4.5px",
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    "& .MuiSlider-valueLabel": {
      width: "53px",
      height: "27px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      color: 'black',
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '&:hover .MuiSlider-valueLabel': {
        visibility: 'visible',
        opacity: 1,
      },
      "& > span": {
        width: "47px",
        height: "19px",
        backgroundColor: "#ECF1FF",
        color: "#7192FF",
        transform: "none",
        borderRadius: "6px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: "11px",
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: "-6px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "0",
          height: "0",
          borderLeft: "6px solid transparent",
          borderRight: "6px solid transparent",
          borderTop: "6px solid #ECF1FF",
        },
      },
      "& > span span": {
        color: "#7192FF",
        transform: "none",
        borderRadius: "8px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: "11px",
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: "-6px",
          left: "50%",
          transform: "translateX(-50%)",
          borderLeft: "6px solid transparent",
          borderRight: "6px solid transparent",
          borderTop: "6px solid #ECF1FF",
        },
      },
    }
  })
  
  getResponseMessage = () => {
    const { responseShow } = this.state;
    return responseShow.success || responseShow.errors;
  };
  handleAccept = () => {
    let tokenData = JSON.parse(String(localStorage.getItem("token")))
    const userRole = tokenData?.user_role;

    if (this.state.selectedOption === '') {
      this.setState({ hasError: true });
    } else {
      this.acceptedRequest();
     this.handleCloseDialog()
     if (userRole === 'lawfirm_admin') {
      this.getTaskLists();
  } else if (userRole === 'lawyer') {
      this.lawyerTasks();
  }
   
      this.setState({ hasError: false });
    }
  };
  handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    const selectedOption = this.state.optionsShow.find(option => option.value === selectedValue);

    if (selectedOption) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          legal_service_type_id: Number(selectedOption.id),
        },
        selectedOption: selectedValue, 
        hasError: false, 
      }));
    }
  };
  hadnleReject = () => {
    let tokenData = JSON.parse(String(localStorage.getItem("token")))
    const userRole = tokenData?.user_role;
    if (this.state.selectedOption === '') {
      this.setState({ hasError: true });
    } else {
      this.rejectRequest();
      this.handleRejectClose();
      if (userRole === 'lawfirm_admin') {
        this.getTaskLists();
    } else if (userRole === 'lawyer') {
        this.lawyerTasks();
    }
      this.setState({ hasError: false });
    }
  };
  
  getBackgroundColor(selectedOption: string, isDisabled: boolean) {
    return selectedOption === "NO LEGAL CASE" ? "#355EE7" : (isDisabled ? "#F0F0F0" : "#355EE7");
  }
  getBackgroundColor2(selectedOption: string, isDisabled: boolean) {
    return selectedOption === "NO LEGAL CASE" ? "white" :(isDisabled ? "grey" : "white");
  }

  getStyles() {
    const isDisabled = this.state.selectedOption === ''|| this.state.formData2.rejection_description == '';
    const isDisabled2 = this.state.selectedOption === '';
    const backgroundColor = this.getBackgroundColor(this.state.selectedOption, isDisabled);
    const backgroundColor2 = isDisabled2 ? "#F0F0F0" : "#355EE7";
    const textColor = this.getBackgroundColor2(this.state.selectedOption, isDisabled);
    const textColor2 = isDisabled2 ? "grey" : "white";
    const labelColor = this.state.hasError ? "red" : "black";  
    const borderColor = this.state.hasError ? '1px solid red' : '1px solid #C3D1FF';
  
    return { backgroundColor, textColor,textColor2, labelColor, borderColor, isDisabled ,isDisabled2,backgroundColor2};
  }
 
  // Customizable Area End

  render() {
    // Customizable Area Start
  
    const { openPop, searchQuery, selectedLawyers} = this.state;    
    const { opendialog, rejectDialog,openDialogLawyer } = this.props;
    const { backgroundColor, textColor, labelColor, borderColor,backgroundColor2,textColor2 } = this.getStyles();

    return (
      <>
        <Box data-test-id="RequestManagement" style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          zIndex: 1300,
          position: "absolute",
          left: "38%",
          top: "20%"

        }}>
          {opendialog && (
            <CustomizeDialog data-test-id="customizeDialog" >

              <Box  onClick={this.closePop} data-test-id="closePop"  style={{
                marginLeft: "24px", fontFamily: "Dm sans", fontWeight: 500,
                fontSize: "22"
              }}>
                <Typography style={{
                  display: "flex",
                  height: "30px"
                }}>
                  <IconButton
                    onClick={this.handleCloseDialog}
                    style={{ marginLeft: "auto", marginBottom: "8%", width: "24px", height: "24px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Typography>

                <Typography data-test-id="accept1" > {configJSON.acceptText}</Typography>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 400, color: "#8C8C8C ", fontFamily: "Dm sans", width: "98%" }}>
                 {configJSON.acceptPara}</Typography>
              </Box>
              <DialogContent style={{
                marginTop: "11px",
                overflowY:"visible"
              }} >
                <Box display="flex" flexDirection="column">
                  <Box>
                    <InputLabel

                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        marginBottom: "4px",
                        fontFamily: "Dm sans",
                        color: labelColor
                      }}
                    >
                      Legal Service Type
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>

                    <Box
                      style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginTop: "10px" }}>

                      <select
                        id="customSelect"
                        data-test-id="countrySelect"
                        className="selectCountry"
                        onChange={this.handleSelectChange}
                        style={{
                          border: borderColor,
                          borderRadius: '12px',
                          width: '100%',
                          padding: '8px',
                        }}
                      >
                        <option style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color: "grey"
                        }} value="" disabled selected>
                          Select an option
                        </option>
                        {this.state.optionsShow?.map(option => (
                          <option key={option.id} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>


                    </Box>
                  </Box>

                  <Box className="fontsizing" style={{ marginTop: "32px" }}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        marginBottom: "4px",
                        fontFamily: "Dm sans",
                        color: "black"
                      }}
                    >
                      Price €
                      <span
                        style={{
                          fontWeight: 400,
                          color: "#B0B0B0",
                          marginLeft: "2px",
                          fontFamily: "Dm sans",
                        }}
                      >
                        (EUR)
                      </span>
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      value={this.state.formData.prices}
                      onChange={this.handlePriceChangeText}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box display="flex" flexDirection="column" alignItems="center">
                              <ArrowDropUpIcon onClick={this.incrementPrice} style={{ cursor: 'pointer', width: "23px", height: "18px" }} />
                              <ArrowDropDownIcon onClick={this.decrementPrice} style={{ cursor: 'pointer', width: "23px", height: "18px" }} />
                            </Box>
                          </InputAdornment>
                        ),
                        inputProps: {
                          style: {
                            color: 'black',
                            backgroundColor: this.state.price ? '#F5F5F5' : 'none',
                            width: "10%",
                            padding: "4px",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "inherit",
                            alignItems: "center",
                            border: "none",
                            marginRight: "82%",
                          },
                        },
                      }}
                      style={{
                        width: '96%',
                        border: '1px solid #C3D1FF',
                        borderRadius: '12px',
                        fontSize: '16px',
                        fontWeight: 400,
                        background: 'none',
                        outline: 'none',
                        height: '25px',
                        fontFamily: 'Dm Sans',
                        padding: '10px 8px',
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    />

                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "#7192FF",
                        height: "42px"
                      }}
                    >
                      <p style={{color: "#7192FF",
                        fontFamily: "Dm sans",
                        fontSize: "16px",
                        fontWeight: 400,}}>1.500</p>
                      <p style={{
                        color: "#7192FF",
                        fontSize: "16px",fontWeight: 400,
                        fontFamily: "Dm sans" }}>2.500
                        </p> </Typography>
                    <this.PrettoSlider

                      value={this.state.formData.prices}
                      onChange={this.handlePriceChange}
                      min={1500}
                      max={2500}
                      aria-label="pretto slider"
                      step={50}

                      marks={[{ value: 1.500 }, { value: 2.500 }]}

                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => `€ ${(value / 1000).toFixed(3)}`}
                    />
                  </Box>
                </Box>
           
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <Typography style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      marginBottom: "4px",
                      fontFamily: "Dm sans"
                    }}>
                      Assign Lawyer
                    </Typography >
                    <SeeAllLawyersCatalogueButton data-test-id="lawyers-catalogue" onClick={this.handleNavigtaeToLawyersCatalogue} >
                      <span>See all lawyers catalogue</span>
                      <LaunchRoundedIcon htmlColor="#DADADA" style={{width: 17, height: 17}} />
                    </SeeAllLawyersCatalogueButton>

                  </Box>

                 <Box style={{
                  position:"relative"
                 }}>
                  <TextField
                    placeholder="Search by Lawyer name, service or jurisdiction"
                    value={searchQuery}
                    onChange={this.handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{
                            color: "DADADA",
                            width: "24px",
                            height: "24px"
                          }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      inputProps: {
                        style: {

                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        color: "black",
                      },
                    }}
                    className="selectBox"
                  />
                  {openPop && (
            
            <Box
              style={{
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "96.5%",
                left: "50%",
                transform: "translate(-50%,1%)",

                zIndex: 1300,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
                height: "250px",
                overflowY: "auto",
                width: "98%"
              }}
            >

              <List style={{ maxHeight: "400px", overflowY: "auto" }}>
                {this.state.searchResults.length > 0 ? (
                  this.state.searchResults?.map((lawyer, index) => {

                    return (
                      <>
                        <ListItem style={{
                          height: "45%",
                          marginTop:"8px"
                        }} key={index} 
                        button data-test-id="clickButton" onClick={() => this.handleLawyerSelect(lawyer)}>
                          <Checkbox data-test-id="onChangeClick" style={{
                            marginBottom: "11%"
                          }}
                            checked={selectedLawyers.some((selected) => selected?.attributes?.associated_lawyer_details?.data?.attributes?.first_name === lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.first_name)}
                            onChange={() => this.handleLawyerSelect(lawyer)}
                          />
                          <Box style={{
                            width: "99%",

                            display: "flex",
                            borderBottom: "1px solid #C3D1FF",
                            padding: "8px",
                            gap: "6px"
                          }}>
                            <ListItemAvatar>
                              <Avatar style={{
                                marginBottom: "3%",
                                width: "56px",
                                height: "56px",
                                marginLeft: "3px"
                              }} src={userClient2} />
                            </ListItemAvatar>
                            <Box style={{
                              display: "flex",
                              flexDirection: "column"
                            }} >
                              <Box
                                style={{
                                  display: "flex",

                                  gap: "15px",
                                  fontFamily: "Dm sans",

                                }}
                              >
                                <Typography style={{ fontWeight: 400, fontSize: "14px", fontFamily: "Dm sans", marginLeft: "2px" }}>
                                  {lawyer.attributes?.associated_lawyer_details?.data?.attributes?.first_name} {lawyer.attributes.associated_lawyer_details.data.attributes.last_name}
                                </Typography>
                                <Typography style={{ fontWeight: 400, fontSize: "16px", fontFamily: "Dm sans", color: "#8C8C8C", gap: "3px", display: "flex", height: "1px" }}>
                                  4.2<Typography><StarRateIcon style={{
                                    color: "#F59E0B",
                                    width: "19px",
                                    height: "25px"
                                  }} /></Typography>
                                </Typography>
                              </Box>
                              <Box style={{
                                display: "flex",

                              }}>
                                <Typography
                                  variant="body2"
                                  style={{
                
                                    fontFamily: "Dm sans",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "2px",
                                    gap: "3px"}}>
                                  <VpnLockIcon style={{
                                    height: "18px",
                                    width: "18px",
                                    color: "#355EE7",}}/>
                                     <span 
                                     style={{
                                    fontSize: "11px",
                                    fontFamily: "Dm sans",
                                    fontWeight: 400}}>
                                      {lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.jurisdiction}
                                      </span></Typography>
                              </Box><Box 
                              style={{
                                display: "flex",
                                gap: "8px"  }}>

{lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.legel_service_type?.slice(0, 2)?.map((service, index) => (
                                <Typography style={{ color: "#355EE7",
                                    backgroundColor: "#ECF1FF",
                                    borderRadius: "6px",
                                    marginLeft: "2px",
                                    
                                    fontWeight: 400,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    padding: "2px 6px",
                                    width: "auto",
                                    height: "auto",
                                    fontSize: "11px",
                                    fontFamily: "Dm sans",
                                    marginTop: "3px",}}
                                  key={index}>

                                  <img src={Services}
                                    style={{ width: "14px",
                                      backgroundColor: "none",
                                      height: "14px",
                                      marginRight: "4px", }}alt=""/>
                                  <span>{service.legal_service_name}
                                  </span> </Typography>
                             ))}
                               { lawyer && lawyer.attributes.associated_lawyer_details.data.attributes.legel_service_type?.length > 2 && ( <Typography
                        style={{
                          color: "#355EE7",
                          backgroundColor: "#ECF1FF",
                          borderRadius: "6px",
                          padding: "2px 6px",
                          fontFamily: "Dm sans",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          marginTop: "3px",
                          marginLeft: "2px",
                          width: "auto",
                          height: "auto",
                          fontSize: "11px",
                          fontWeight: 400, }}>
                        <img src={Services}
                          style={{
                            width: "14px",
                          
                            height: "14px",
                            backgroundColor: "none",
                            marginRight: "4px",}}alt=""/>
                        <span>+{lawyer?.attributes.associated_lawyer_details.data.attributes?.legel_service_type.length - 2}
                        </span>  </Typography>
                    )}
                              </Box></Box>
                                </Box>
                        </ListItem></>
                    )
                  }
                  )
                ) : (
                  <Typography style={{ padding: "16px" }}>No Current Associated Lawyers</Typography>
                )}
              </List>
            </Box>

      
        )}
                  </Box>
                  {this.state.selectedLawyers.length > 0 && (
                    <Box mt={2} className="selectedUser">
                      {this.state.selectedLawyers?.map((lawyer, index) => (
                        <Box data-test-id={`mapFunction${index}`}
                        key={lawyer?.attributes?.associated_lawyer_details.data.id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            border: '1px solid #C3D1FF',
                            borderRadius: "10px",
                            alignItems: "center",
                            gap: "8px",
                            padding: "6px",
                            fontFamily: "Dm sans",
                          }}

                        >
                          <Avatar src={userClient2} style={{
                            marginBottom: "3%",
                            width: "56px",
                            height: "56px",
                            marginLeft: "3px"
                          }} />
                          <Box>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "15px",
                                fontFamily: "Dm sans",
                                width: "100%"
                              }}
                            >
                              <Typography style={{ fontWeight: 400, fontSize: "14px", fontFamily: "Dm sans", marginLeft: "2px" }}>
                                {lawyer.attributes.associated_lawyer_details.data.attributes.first_name}  {lawyer.attributes.associated_lawyer_details.data.attributes.last_name}
                              </Typography>
                              <Typography style={{ fontWeight: 400, fontSize: "16px", fontFamily: "Dm sans", color: "#8C8C8C", gap: "3px", display: "flex" }}>
                                4.2 <Typography><StarRateIcon style={{
                                  color: "#F59E0B",
                                  width: "19px",
                                  height: "25px"
                                }} /></Typography>
                              </Typography>
                            </Box>
                            <Box style={{
                              display: "flex",
                              flexDirection: "column"
                            }}>
                              <Typography
                                variant="body2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "2px",
                                  fontFamily: "Dm sans",
                                  gap: "3px"
                                }}
                              >
                                <VpnLockIcon style={{
                                  height: "18px",
                                  width: "18px",
                                  color: "#355EE7",
                                }} /> <span style={{
                                  fontSize: "11px",
                                  fontFamily: "Dm sans",
                                  fontWeight: 400
                                }}>{lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.jurisdiction}</span>
                              </Typography>
                            </Box>
                            <Box style={{
                              display: "flex",
                              gap: "8px"
                            }}>
                                   {lawyer?.attributes?.associated_lawyer_details?.data?.attributes?.legel_service_type?.slice(0, 2)?.map((service, index) => (
                                <Typography
                                  style={{
                                    color: "#355EE7",
                                    backgroundColor: "#ECF1FF",
                                    borderRadius: "6px",
                                    padding: "2px 6px",
                                    fontFamily: "Dm sans",
                                    marginTop: "3px",
                                    marginLeft: "2px",
                                    width: "auto",
                                    height: "auto",
                                    fontSize: "11px",
                                    fontWeight: 400,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                  key={index}
                                >

                                  <img
                                    src={Services}
                                    style={{
                                      width: "14px",
                                      backgroundColor: "none",
                                      height: "14px",
                                      marginRight: "4px",
                                    }}
                                    alt=""
                                  />
                                  <span>{service.legal_service_name}</span>
                                </Typography>
                             ))}
                                   { lawyer && lawyer.attributes.associated_lawyer_details.data.attributes.legel_service_type?.length > 2 && (
                      <MuiThemeProvider theme={theme}>
                      <Tooltip
                        title={
                          <div  style={{ width:"100%"}} >
                            <div style={{display:"flex",gap:"20px",width:"100%"}}>
                              <div style={{display: "grid",gridTemplateColumns:" repeat(2, 1fr)",gap:"10px",width:"80%",marginTop:"40px"}}>
                                {lawyer?.attributes.associated_lawyer_details.data.attributes.legel_service_type?.slice(2).map((service, index) => (
                                  <Chip
                                    key={index}
                                    label={service.legal_service_name}
                                    className="popup-chip"
                                    style={{ backgroundColor: '#ECF1FF ',
                                    color: '#355EE7 ',fontSize:"14px",textTransform:"capitalize",wordWrap: "break-word"}}
                                  />
                                ))}
                              </div>
                                    <div className="popup-image" style={{ justifyContent: "flex-end", display: "flex"}}>
                                    <img
                                  src={tooltipIcon}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    marginBottom:"1px",
                                  }}
                                  alt=""
                                />
                                    </div>
                            </div>
                          </div>
                        }
                      >
                        <Typography
                          style={{
                            color: "#355EE7",
                            backgroundColor: "#ECF1FF",
                            borderRadius: "6px",
                            padding: "2px 6px",
                            fontFamily: "Dm sans",
                            marginTop: "3px",
                            marginLeft: "2px",
                            width: "auto",
                            height: "auto",
                            fontSize: "11px",
                            fontWeight: 400,
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img
                            src={Services}
                            style={{
                              width: "14px",
                              backgroundColor: "none",
                              height: "14px",
                              marginRight: "4px",
                            }}
                            alt="Service"
                          />
                          <span>+{lawyer?.attributes.associated_lawyer_details.data.attributes?.legel_service_type.length - 2}</span>
                        </Typography>
                      </Tooltip>
                    </MuiThemeProvider>
                    )}
                            </Box>
                          </Box>
                          <IconButton data-test-id="iconButton"                
                         onClick={() => this.handleRemoveSelectedLawyer(lawyer)}
                            style={{ marginLeft: "auto", marginBottom: "8%", width: "24px", height: "24px" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

              </DialogContent>
              <DialogActions style={{
                width: "94%"
              }}>
                <Button
                  onClick={this.handleCloseDialog}
                  data-test-id="cancelBtn1"
                  style={{
                    border: "1px solid #8C8C8C",
                    width: "150px",
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Dm sans",
                    color: "#8C8C8C",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  data-test-id="acceptBtn-1"
                  onClick={this.handleAccept}
                  style={{
                    backgroundColor: backgroundColor2 ,
                    color:textColor2,
                    borderRadius: "8px",
                    width: "150px",
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Dm sans",
                    textTransform: "none",
                 
                  }}
                >
                  Accept
                </Button>
              </DialogActions>

            </CustomizeDialog>
          )}
           {openDialogLawyer && (
            <CustomizeDialog 
            data-test-id="customize2" >
              <Box style={{
                marginLeft: "24px", 
                fontFamily: "Dm sans", 
                fontWeight: 500,
                fontSize: "22" }}>
                <Typography style={{
                  display: "flex",
                  height: "30px"
                }}><IconButton
                    onClick={this.handleCloseDialog}
                    style={{ marginLeft: "auto",
                     marginBottom: "8%",
                      width: "24px", 
                      height: "24px" }}>
                    <CloseIcon /></IconButton>
                </Typography> <Typography> Accepting Task</Typography>
                <Typography style={{ fontSize: "14px", 
                  fontWeight: 400,
                   color: "#8C8C8C ", 
                   fontFamily: "Dm sans",
                   width: "98%"
                    }}>Before accepting this task it is necessary to provide additional
                  information
                  </Typography> </Box>
              <DialogContent 
              style={{ marginTop: "11px"}} >
                <Box display="flex" 
                flexDirection="column"> <Box>
                    <InputLabel  style={{
                         fontSize: "14px",
                         marginBottom: "4px",
                        fontWeight: 700,
                        fontFamily: "Dm sans",
                        color: labelColor 
                        }} >
                      Legal Service Type
                      <span 
                      style={{
                         color: "red" 
                         }}>*</span>
                    </InputLabel> <Box
                      style={{ 
                        display: 'flex',
                         alignItems: 'center',
                          position: 'relative',
                           width: '100%', 
                           marginTop: "10px" }}>

                      <select
                        id="customSelect"
                        className="selectCountry"
                      data-test-id="selectedId"
                        onChange={this.handleSelectChange}
                        style={{padding: '8px',
                          border:borderColor,
                          borderRadius: '12px',
                          width: '100%',}}>
                        <option style={{fontSize: '16px',
                          fontWeight: 400,
                          color: "grey" }} 
                          value="" disabled selected>
                          Select an option</option>
                        {this.state.optionsShow?.map(option =>(
                          <option key={option.id} value={option.value}>
                            {option.label}
                          </option> ))} </select>
                           </Box> </Box>
                  <Box className="fontsizing"
                   style={{ marginTop: "32px" }}>
                    <Typography style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        marginBottom: "4px",
                        fontFamily: "Dm sans",
                        color: "black"  }}  >
                      Price €
                      <span
                        style={{fontWeight: 400,
                          fontFamily: "Dm sans",
                          color: "#B0B0B0",
                          marginLeft: "2px",}}  >
                        (EUR)</span>
                      <span style={{ color: "red" }}>*</span>
                    </Typography><TextField
                      value={this.state.formData.prices}
                      onChange={this.handlePriceChangeText}
                      InputLabelProps={{
                        shrink: true,  }}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box display="flex" flexDirection="column" alignItems="center">
                              <ArrowDropUpIcon onClick={this.incrementPrice} style={{ cursor: 'pointer', width: "23px", 
                              height: "18px" }} 
                              />
                              <ArrowDropDownIcon onClick={this.decrementPrice} style={{ cursor: 'pointer', width: "23px",
                               height: "18px" }} 
                               />
                            </Box> </InputAdornment>
                        ),
                        inputProps: {style: {
                            color: 'black',
                            backgroundColor: this.state.price ? '#F5F5F5' : 'none',
                            width: "10%",
                            display: "flex",
                            justifyContent: "inherit",
                            alignItems: "center",
                            border: "none",
                            marginRight: "82%",
                            padding: "4px",
                            borderRadius: "8px", },}, }}
                      style={{
                        width: '96%',
                        outline: 'none',
                        height: '25px',
                        fontFamily: 'Dm Sans',
                        padding: '10px 8px',
                        display: "flex",
                        justifyContent: "space-between",
                        border: '1px solid #C3D1FF',
                        borderRadius: '12px',
                        fontSize: '16px',
                        fontWeight: 400,
                        background: 'none',}}
                    />
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "#7192FF",
                        height: "42px"}}>
                      <p style={{
                        color: "#7192FF",
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Dm sans"
                      }}>1.500</p>
                      <p style={{
                        color: "#7192FF",
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Dm sans"
                      }}>2.500</p>
                    </Typography>
                    <this.PrettoSlider
                      value={this.state.formData.prices}
                      onChange={this.handlePriceChange}
                      min={1500}
                      max={2500}
                      aria-label="pretto slider"
                      step={50}
                      marks={[{ value: 1.500 }, { value: 2.500 }]}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => `€ ${(value / 1000).toFixed(3)}`}/>
                  </Box> </Box>
              </DialogContent> <DialogActions style={{
                width: "94%"}}>
                <Button
                  onClick={this.handleCloseDialog}
                  data-test-id="cancelBtn2"
                  style={{
                    border: "1px solid #8C8C8C",
                    alignItems: "center",
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Dm sans",
                    color: "#8C8C8C",
                    textTransform: "none",
                    width: "150px",
                    height: "44px",
                    display: "flex",   
                  }}>
                  Cancel</Button><Button
                  data-test-id="acceptBtn-1" onClick={this.handleAccept}
                  style={{
                    backgroundColor: backgroundColor2 ,
                    color:textColor2,
                    borderRadius: "8px",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Dm sans",
                    textTransform: "none",
                    width: "150px",
                    height: "44px",
                    display: "flex",  }}>
                  Accept </Button></DialogActions>
                  </CustomizeDialog> )}
          {rejectDialog && (
            <CustomizeDialog data-test-id="customize3"  >

              <Box style={{
                marginLeft: "24px", fontFamily: "Dm sans", fontWeight: 500,
                fontSize: "22"
              }}>
                <Typography style={{
                  display: "flex",
                  height: "30px"
                }}>
                  <IconButton
                    onClick={this.handleRejectClose}
                    style={{ marginLeft: "auto", marginBottom: "8%", width: "24px", height: "24px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Typography>

                <Typography> Rejecting Task</Typography>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 400, color: "#8C8C8C ", fontFamily: "Dm sans", width: "98%" }}>Before rejecting this offer it is necessary to provide a clear reason to the lawyer.</Typography>
              </Box>
              <DialogContent style={{
                marginTop: "11px"
              }} >
                <Box display="flex" flexDirection="column">
                  <Box>
                    <InputLabel

                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        marginBottom: "4px",
                        fontFamily: "Dm sans",
                        color: labelColor
                      }}
                    >
                      Rejection Reason
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>

                    <Box
                      style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginTop: "10px" }}>

                      <select
                        id="customSelect"
                        className="selectCountry"
                        onChange={this.handleChange2}
                        style={{
                          border: borderColor,
                          borderRadius: '12px',
                          width: '100%',
                          padding: '8px',
                        }}
                      >
                        <option style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color: "grey"
                        }} value="" disabled selected>
                          Select an option
                        </option>
                        {this.state.optionsReason?.map(option => (
                          <option key={option.id} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </Box>
                  </Box>
                </Box>
                {this.state.selectedOption !== "NO LEGAL CASE" && (
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <Typography style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      marginBottom: "4px",
                      fontFamily: "Dm sans",
                      color: labelColor
                    }}>
                      Reason description
                      <span style={{ color: "red" }}>*</span>
                    </Typography >


                  </Box>
                  <textarea
                    onChange={this.handleChange}
                    placeholder="Describe the rejection reason"
                    style={{
                      width: "100%",
                      height: "150px",
                      borderRadius: "12px",
                      border: borderColor,
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "Dm sans",
                      marginBottom: "10px",
                      padding: "8px"
                    }}>

                  </textarea>
                </Box>
                )}
              </DialogContent>
              <DialogActions style={{
                width: "94%"
              }}>
                <Button
                  data-test-id="cancelBtn3"
                  onClick={this.handleRejectClose}
                  style={{
                    border: "1px solid #8C8C8C",
                    width: "150px",
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Dm sans",
                    color: "#8C8C8C",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  data-test-id="reject1"
                  onClick={this.hadnleReject}
                  style={{
                    backgroundColor: backgroundColor, 
                    borderRadius: "8px",
                    width: "150px",
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Dm sans",
                    textTransform: "none",
                    color:textColor
                  }}
                >
                  Save & Reject
                </Button>
              </DialogActions>

            </CustomizeDialog>
          )}
         
     
        </Box>
        
        <Dialog
          PaperProps={{
            style: {
              width: '59vh',
              height: "auto",
              borderRadius: "12px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }
          }} style={{}} open={this.state.openDialog} onClose={this.handleCloseDelete}
          aria-labelledby="responsive-dialog-title" >

          <Box 
          className=""
          sx={{
            width: "57vh",
            borderRadius: "8px",
            border: "1px solid #D1FAE5",
            height: "215px",
          }}>
            <DialogTitle id="responsive-dialog-title" style={{
              textAlign: 'right',
              padding: "7px"
            }}><CloseIcon style={{ cursor: 'pointer' }} onClick={this.handleCloseDelete} /></DialogTitle>
            <DialogContent
              style={{
                padding: "0px"
              }}
            >
              <DialogContentText  >
                <Typography style={{
                  backgroundColor: '#ffffff',
                  color: '#0F172A',
                  fontSize: '19px',
                  fontWeight: 400,
                  fontFamily: 'DM Sans',
                  marginBottom: '20px',
                }}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <img src={right} className="imageBox2" alt="success" />
                  </Box>
                  <Typography style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "25px",
                    fontSize: "22px",
                    fontFamily: "Dm sans",
                    fontWeight: 400
                  }}>
                    {this.getResponseMessage()}
                  </Typography>
                </Typography>
              </DialogContentText>
            </DialogContent></Box>


        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const CustomizeDialog = styled(Box)({
  "& .selectCountry": {
    width: '100%',
    border: '1px solid #C3D1FF', borderRadius: "12px", outline: 'none', backgroundColor: '#fff',
    fontSize: '16px', height: "44px",
    backgroundRepeat: 'no-repeat', backgroundPositionX: '98%',
    backgroundImage: 'url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%22%20height%3D%22100%22%20viewBox%3D%220%200%20100%20100%22%3E%3Cpath%20d%3D%22M10%2010h80v80H10z%22%20fill%3D%22%23f00%22%2F%3E%3C%2Fsvg%3E")',
    fontWeight: 400, fontFamily: "DM Sans",
    backgroundPositionY: 'center', appearance: 'none',
    backgroundSize: "17px",
    paddingLeft: "8px"
  },
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  boxShadow: "  0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008,0px 25px 50px 0px #00000017",
  borderRadius: "12px",
  padding: "15px 9px 9px 9px",
  width: "580px",
  background: "white",

  "& .MuiPaper-rounded ": {
    bordeRadius: "17px",
  },
  "& .mainDiv": {
    background: "#FFF",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    borderRadius: "16px",
    padding: "32px 24px 32px 24px",
    gap: "20px",
    fontFamily: "Dm sans",

  },
  "& .selectlegal": {
    width: '100% !important',
    borderRadius: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    height: "44px",
    "& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px"

    },
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px",
      boxShadow: "0px 0px 0px 0px"

    },
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px",
    },
  },
  "& .selectBox": {
    border: "1px solid #C3D1FF",
    position:"relative",
    width: "97%",
    borderRadius: "12px",
    color: "black", height: "44px",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "6px",
    paddingRight: "6px",
    "& .MuiInputBase-input::placeholder": {
      color: "#BFBFBF",
    },
    "& .div-root-1 .MuiInputBase-input": {
      padding: "0px"
    }
  },
  "& .forGap": {
    padding: "4px",
  },
  "& . moneySlider": {
    color: "red",
  },
  "& .fontsizing": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#212121",
  },
  "& .selectedUser": {
    border: '1px solid #F0F0F0', borderRadius: '10px', display: 'flex', flexDirection: 'column', gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "97.5%",
    padding: "6px"
  },
  "& .selectedUser2": {
    border: '1px solid #C3D1FF',
    borderRadius: '10px',
    display: 'flex',
    gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "100%"
  }
});


// Customizable Area End
